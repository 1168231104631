<template>

    <v-row>
      <v-col cols="auto" sm="12" md="6" >
        <v-row
          class="about-app-section align-content-sm-center"
          color="transparent"
          elevation="0"
        >
          <transition @before-enter="title" @enter="title1" appear>
            <h1 class="title-app-section">اكتشف المدينة القديمة</h1>
          </transition>

          <transition appear @before-enter="appParagraph" @enter="appParagraph1">
            <p class="text-app-section-card">
              تطبيق يتيح لك إنجاز معاملاتك داخل المنارة الخاصة بمدينة حلب القديمة بالإضافة
              إلى التعرف <br />على كافة الأنشطة والخدمات التي تقدمها المنارة وعرض كافة
              الفرص المتاحة في المدينة القديمة من فرص ( تمويل - استثمار - عمل )
            </p>
          </transition>

          <transition appear @before-enter="btnDownload" @enter="btnDownload_1">
            <v-btn
              variant="outlined"
              density="combact"
              class="btn-download-app py-3 px-5 mt-20"
              height="8vh"
              width="25%"
              ><a href="./#download"><span>تحميل التطبيق </span></a>
            </v-btn>
          </transition>
        </v-row>
      </v-col>
      <v-col cols="auto" sm="12" md="6" >
        <transition appear @before-enter="phoneImage" @enter="phoneImage1">
          <v-row class="phone">
            <v-img
              class="phone-image "
              :src="imgUrl"
              alt=""
              aspect-ratio="4/3"
            >
              <transition appear @before-enter="card_2" @enter="card">
                <img
                  class="card2 position-absolute hidden-sm hidden-xs"
                  :src="imgCard2"
                  alt=""
                  height="30%"
                />
              </transition>
              <transition appear @before-enter="card_1" @enter="card_flag">
                <img
                  class="card1 position-absolute hidden-sm hidden-xs"
                  :src="imgCard1"
                  alt=""
                  height="30%"
                /> </transition
            ></v-img>
          </v-row>
        </transition>
      </v-col>
    </v-row>
</template>

<script>
import gsap from "gsap";
export default {
  data() {
    return {
      imgUrl: require("../../assets/images/landing page-1 .png"),
      imgCard1: require("../../assets/images/card2.png"),
      imgCard2: require("../../assets/images/card1.png"),
    };
  },
  methods: {
    title(el) {
      el.style.opacity = "0";
      el.style.transform = "translateY(-50px)";
    },
    title1(el) {
      gsap.to(el, {
        opacity: 1,
        y: 10,
        duration: 2,
        ease: "in-out",
      });
    },

    appParagraph(el) {
      el.style.opacity = "0";
      el.style.transform = "translateY(-100px)";
    },
    appParagraph1(el) {
      gsap.to(el, {
        duration: 1,
        y: 10,
        opacity: 1,
        delay: 1,
        ease: "in-out",
      });
    },
    btnDownload(el) {
      el.style.opacity = "0";
      el.style.transform = "scale(0.01)";
    },
    btnDownload_1(el) {
      gsap.to(el, {
        opacity: 1,
        scale: 1.03,
        duration: 0.5,
        delay: 2,
      });
    },
    phoneImage(el) {
      el.style.opacity = "0";
      el.style.transform = "scale(0.01)";
    },
    phoneImage1(el) {
      gsap.to(el, {
        opacity: 1,
        duration: 0.5,
        scale: 1,
        delay: 2.5,
      });
    },

    card_2(el) {
      el.style.opacity = "0";
      el.style.transform = "scale(0.01)";
    },
    card(el) {
      gsap.to(el, {
        //repeat:-1,
        opacity: 1,
        scale: 1,
        duration: 0.5,
        delay: 3,
      });
    },
    card_1(el) {
      el.style.opacity = "0";
      el.style.transform = "scale(0.01)";
    },
    card_flag(el) {
      gsap.to(el, {
        //repeat:-1,
        opacity: 1,
        scale: 1,
        duration: 0.5,
        delay: 4,
      });
    },
  },
};
</script>

<style lang="scss">
.phone {
  height: 80vh;

  .phone-image {
    .card2 {
      left:6%;
      top: 42%;
    }
  }
  .card1 {
    left: 20%;
    top: 59%;
  }
}

.about-app-section {
  position: relative;
  top: 10vh;
  display: flex;
  flex-wrap: wrap;
  align-content: start;

  .title-app-section {
    text-align: start;
    color: white;
    font-family: "Cairo", sans-serif;
  }

  .text-app-section-card {
    text-align: start;
    margin-top: 19px;
    color: #ffffff;

    font-family: "Cairo", sans-serif;
  }

  .btn-download-app {
    border-radius: 12px;
    margin-top: 6vh;
    color: #e8bd70;
    letter-spacing: normal;

    a {
      text-decoration: none;

      span {
        color: #e8bd70;
      }
    }
  }
}
</style>
