<template>
  <NavBar />
  <router-view />
  <Footer />
</template>
<script>
import NavBar from "./components/landingPage/NavBar.vue";
import Footer from "./components/landingPage/Footer.vue";
export default {
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
  direction: rtl;
}

/*body {
}*/

#app {
  font-family: "Cairo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // .v-locale--is-rtl {
  //  direction: rtl;
  //}
}
</style>
