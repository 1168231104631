import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


// Vuetify
import "@mdi/font/css/materialdesignicons.css";
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { ar,en } from 'vuetify/locale'





  
const vuetify = createVuetify({
  locale: {
    locale: 'ar',
    fallback: 'en',
    messages: { ar,en},
  },
  components,
  directives,
  

  theme:
  {
    defaultTheme:'dark'
    
  }
})

createApp(App).use(vuetify,{ rtl:true}).use(router).mount('#app')
