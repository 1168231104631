<template>

  <v-layout class="layout">
   
      <v-footer class="footer" height="fit-content">
        <v-container fluid style="margin-left: 8.5%; margin-right: 8.5%" height="fit-content">
      <v-row>
        <v-col cols="12" class="align-start">
          <img  class="d-flex align-start" src="../../assets/images/logo_web_grey.svg" alt="" style="width: 146px" />
        </v-col>
        <v-col cols="12">
          <h4 class="contact font-weight">تواصل معنا</h4>

          <v-row>
          
            <v-col cols="12">
              <v-row>
                <v-col lg="3" md="3" sm="12" class="contact-us" v-for="connect in connection_info" :key="connect" 
                > <v-icon :icon="connect.icon"></v-icon> <span class="contact-info">{{ connect.title }}</span></v-col>
              
                <v-col cols="12" lg="3" md="3" sm="12" >
                  <v-row no-gutters style="place-content: flex-end;">
                    <template v-for="icon in icons" :key="icon">
                      <!-- <v-col> -->
                        <v-btn  class="color-dark-2 mr-2 ml-2"  min-width="30" width="30" height="30">
                          <v-icon size="22" color="white" class="icon-social">
                            {{ icon.icon }}
                          </v-icon>
                        </v-btn>

                      <!-- </v-col> -->
                    
                    </template>

                  </v-row>
                </v-col>
    

             
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-divider class="px-30 mx-auto my-1" color="black" :thickness="4" style="opacity: 45%"></v-divider>
                <v-col>
                  <v-card color="transparent" elevation="0">
                    <v-card-text style="color: rgba(0, 0, 0, 0.827)">صنع في سوريا بكل حب
                      <v-icon color="red">mdi-heart</v-icon>
                      من قبل شركة
                      ورشة تك
                      <a href="https://www.warshatec.com" target="_blank"> Warshatec </a>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>


        </v-col>

      </v-row>
    </v-container>
    </v-footer>
 
  
  </v-layout>
</template>
<script>
export default {
  data() {
    return {
      icons: [
      {
          icon: "mdi-instagram",
        },
        {
          icon: "mdi-facebook",
        },
      
        {
          icon: "mdi-whatsapp",
        },
      ],
      connection_info: [
        {
          title: "info@damascuse.com",
          icon: "mdi-email-outline",
        },
        {
          title: "963993215454+",
          icon: "mdi-cellphone",
        },
        {
          title: "محافظة حلب-مدينة حلب القديمة",
          icon: "mdi-map-marker-outline",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.layout {
  .footer {
    background-image: url("../../assets/images/footer1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 99%;

    .contact {
      color: rgba(0, 0, 0, 0.8196078431);
      text-align: start;
     
      width: 115px;
height: 45px;

font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 45px;
    }

    .connect-information-text-card {
      color: rgba(0, 0, 0, 0.827);
      display: inline-flex;
      gap: 15px;

      span {
        //margin-inline: -8%;
      }
    }
  }
}

/* محافظة حلب - مدينة حلب القديمة */
.contact-us
{width: 283px;
height: 37px;

font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 37px;
text-align: right;

/* Black-Dark */
color: #0D0D0D;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
}

.contact-info{
  /* +963 993 215 454 */

width: 159px;
height: 37px;

font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 37px;
text-align: right;

color: #0D0D0D;


}
</style>
