<template>
  <div class="home" style="margin: auto;margin-left: 10%;margin-right: 10%">
    <v-container justify-center>
    <AboutApp />
    <Services />
    <DownloadApp />
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import AboutApp from "../components/landingPage/AboutAppSection.vue";
import Services from "../components/landingPage/ServicesSection.vue";
import DownloadApp from "../components/landingPage/DownloadAppSection.vue";

export default {
  name: "HomeView",
  components: {
    AboutApp,
    Services,
    DownloadApp,
  },
};
</script>
<style lang="scss">
.home {
  height: fit-content;
  background: url("../assets/images/lines_background.svg") no-repeat;
  background-size: cover;
}
</style>
