<template>
  <div class="download" id="download">
    <v-container fluid style="margin-left: 8.5%; margin-right: 8.5%" height="fit-content">
      <v-row>
        <v-col cols="6">
          <transition appear @before-enter="appDownload" @enter="appDownload1">
            <div class="title-section">
              <h1>حمل التطبيق الآن</h1>

              <p>
                يمكنك تحميل تطبيق منارة حلب القديمة<br />من خلال المتاجر المتاحة أو تحميله
                برابط خارجي
              </p>

              <h3>تحميل التطبيق من</h3>
            </div>
          </transition>
          <br /><br /><br />
          <v-row>
            <v-col cols="12">
              <transition-group appear @before-enter="downloadBtn" @enter="downloadBtn1">
                <v-card
                  v-for="(btn, index) in Button"
                  :key="btn"
                  :data-index="index"
                  class="btn flex-md-wrap"
                  color="transparent"
                >
                  <v-hover v-slot="{ isHovering, props }" open-delay="200">
                    <v-btn
                      :class="{ 'on-hover': isHovering }"
                      density="combact"
                      rounded="20"
                      class="btn-link py-4 px-10 flex-md-wrap flex-sm-wrap"
                      v-bind="props"
                      :elevation="isHovering ? 6 : 2"
                    >
                      <span>{{ btn.btn_text }}</span>
                      <v-icon class="btn-link-icon" size="35">{{ btn.icon }}</v-icon>
                    </v-btn>
                  </v-hover>
                </v-card>
              </transition-group>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6">
          <div>
              <v-img
                class="hidden-xs mb-5"
                :src="imgDownload2"
                alt=""
                height="50vh"
                width="70%"
              ></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import gsap from "gsap";

export default {
  data() {
    return {
      imgDownload2: require("../../assets/images/Download 1 (1).png"),
      Button: [
        { btn_text: "Google Play", icon: "mdi-google-play" },
        { btn_text: "Apple Store", icon: "mdi-apple" },
        { btn_text: "رابط خارجي", icon: "mdi-download" },
      ],
    };
  },
  methods: {
    appDownload(el) {
      el.style.opacity = "1";
      el.style.transform = "translateY(-50px)";
    },
    appDownload1(el) {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          toggleActions: "restart none none none",
        },
        opacity: 1,
        y: 0,
        duration: 2,
      });
    },
    downloadBtn(el) {
      el.style.opacity = "0";
      el.style.transform = "translateX(-100px)";
    },
    downloadBtn1(el, done) {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          toggleActions: "restart none none none",
        },
        opacity: 1,
        x: 0,
        duration: 0.9,
        delay: el.dataset.index * 0.2,
        ease: "in",
        onComplete: done,
      });
    },
    imgDownload(el) {
      el.style.opacity = "0";
      el.style.transform = "translateX(-50px)";
      el.style.transform = "scale(0.02)";
    },
    imgDownload1(el) {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          toggleActions: "restart none none none",
        },
        opacity: 1,
        x: 3,
        scale: 1,
        duration: 1,
        delay: 4,
      });
    },
  },
};
</script>

<style lang="scss">
.download {
  margin-top: 100px;

  .title-section {
    position: relative;
    right: 4%;
    line-height: 6vh;
    color: white;
    word-spacing: 3px;
    text-align: start;
    font-weight: 500;
  }

  .btn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    float: right;
    gap: 10px;
    .btn-link {
      background-color: #e8bd70;

      //border-radius: 11.31px;

      text-transform: none;

      color: rgb(128, 81, 0);

      width: 200px;
      margin-top: 1vh;

      .btn-link-icon {
        color: white;
      }
      span {
        font-size: 20px;
        color: white;
      }
    }
  }
}
</style>
