<template>
    <v-layout>
      <v-app-bar
  
     
         style="background-color: #212121; opacity:0.9"

       
        height="fit-content"
>
  <v-container fluid style="margin-left: 8.5%; margin-right: 8.5%">
    <v-row>
      <v-col cols="1">
        <img style="opacity:1"class="aleppo" :src="aleppo_logo" />
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1">
        <img style="opacity:1" class="syriaTrust" :src="syriaTrust_logo" />
      </v-col>
    </v-row>
  </v-container>
</v-app-bar>

    </v-layout>
 
</template>
<script>
export default {
  data() {
    return {
      aleppo_logo: require("../../assets/images/logo_web.svg"),
      syriaTrust_logo: require("../../assets/images/SyriaTrust.jpg"),
    };
  },
};
</script>
<style lang="scss">
.app {
  width: 100%;
  .app-bar {
    background-image: url("../../assets/images/old-aleppo.svg");
    background-size: cover;
    .syriaTrust {
      border-radius: 10px;
      width: 3.187em;
      height: 4.875em;
    }
    .aleppo {
      height: 3.4737em;
      width: 18.4469em;
    }
    @media (max-width: 723px) {
      .syriaTrust {
        height: 60px;
        width: 40px;
      }
      .aleppo {
        height: 2em;
        width: 10em;
        left: 19%;
      }
    }
  }
}
</style>
