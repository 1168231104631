<template>
  <div class="services" style="margin-top: 15%">
    <h1 class="app_service_text">خدمات التطبيق</h1>
    <h2 class="manara_service_text">خدمات المنارة</h2>
    <v-container>
      <v-row style="margin-left: 8.5%; margin-right: 8.5%">
        <transition-group appear @before-enter="beforeEnter" @enter="enter">
          <v-col
            v-for="(services, index) in service"
            :key="services.id"
            :data-index="index"
          >
            <v-hover v-slot="{ isHovering, props }">
              <v-card class="card" v-bind="props">
                <v-card-title class="service-title">{{ services.title }}</v-card-title>

                <v-divider
                  class="mx-15"
                  color="#E8BD70"
                  :thickness="5"
                  style="opacity: 100%"
                ></v-divider>

                <v-row>
                  <v-col>
                    <div class="card-icon" style="">
                      <img
                        class="icon-img"
                        :src="services.img1"
                        alt=""
                        height="60%"
                        width="60%"
                        style="margin-top: 5vh"
                      />
                    </div>
                  </v-col>
                </v-row>

                <img
                  class="card-bottom-img mt-auto"
                  src="../../assets/images/114 1.png"
                  alt=""
                />

                <v-expand-x-transition>
                  <v-card
                    v-if="isHovering"
                    class="d-flex bg-orange-lighten-3 v-card--reveal text-h2"
                    style="height: 100%"
                  >
                    <v-list bg-color="transparent">
                      <v-list-item>
                        <v-list-item-title
                          class="sub_service"
                          v-for="service in services.sub_service"
                          :key="service"
                          >{{ service }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-expand-x-transition>
              </v-card>
            </v-hover>
          </v-col>
        </transition-group>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  data() {
    return {
      service: [
        {
          id: 1,
          title: "الفرص",
          img1: new URL("@/assets/images/CHANCE-fill.svg", import.meta.url),
          sub_service: ["فرص تدريب", "فرص تمويل", "فرص تطوع", "فرص عمل"],
        },

        {
          id: 2,
          title: "صندوق دعم المشاريع",
          img1: new URL("@/assets/images/S.P.svg", import.meta.url),
          sub_service: ["القرض التشغيلي", "قرض البركة بالتشغيل"],
        },
        {
          id: 3,
          title: "النافذة الواحدة",
          img1: new URL("@/assets/images/Procedures-fill.svg", import.meta.url),
          sub_service: [" طلب ترميم بناء", " تعويض بناء", " مخطط تفصيلي", " مخطط بناء"],
        },
        {
          id: 4,
          title: "خدمات قانونية",
          img1: new URL("@/assets/images/Procedures-fill.svg", import.meta.url),
          sub_service: ["جلسات توعية", "تدخلات عقارية", "استشارات", "معلومات"],
        },
        {
          id: 5,
          title: "خدمات ثقافية",
          img1: new URL("@/assets/images/culture.svg", import.meta.url),
          sub_service: ["استديو تسجيل", "متحف تفاعلي", "تدريبات تراثية"],
        },
        {
          id: 6,
          title: "خدمات مجتمعية",
          img1: new URL("@/assets/images/socity.svg", import.meta.url),
          sub_service: ["مخاتير ", "لجان مجتمعية"],
        },
      ],
    };
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = "0";
      el.style.transform = "translateY( -100px)";
    },
    enter(el, done) {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          toggleActions: "restart none none none",
        },
        opacity: 1,
        y: 10,
        duration: 0.5,
        delay: el.dataset.index * 0.2,
        ease: "in",
        onComplete: done,
      });
    },
  },
};
</script>
<style>
@import "../../assets/sass/_Services.scss";
</style>
